import JsonCreator from '../../components/JsonCreator'

import { mobCreatorLocalStorageKey } from '../../configuration'

import schema from '../../schemas/mob'

function MobCreator() {
  return (
    <JsonCreator
      title="Mob Creator"
      schema={schema}
      collectionName="mobs"
      localStorageKey={mobCreatorLocalStorageKey}
    />
  )
}

MobCreator.schema = schema

export default MobCreator
