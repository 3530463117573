import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom'
import { CssBaseline } from '@mui/material'

import ApplicationLayout from './components/ApplicationLayout'
import Home from './scenes/Home'
import Spells from './scenes/Spells'
import SpellCreator from './scenes/SpellCreator'
import Decks from './scenes/Decks'
import DeckCreator from './scenes/DeckCreator'
import Families from './scenes/Families'
import FamilyCreator from './scenes/FamilyCreator'
import Mobs from './scenes/Mobs'
import MobCreator from './scenes/MobCreator'
import BattleMaps from './scenes/BattleMaps'
import BattleMapCreator from './scenes/BattleMapCreator'
import Battles from './scenes/Battles'
import BattleCreator from './scenes/BattleCreator'

function App() {
  return (
    <>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={(
              <ApplicationLayout>
                <Home />
              </ApplicationLayout>
            )}
          />
          <Route
            exact
            path="/spells"
            element={(
              <ApplicationLayout>
                <Spells />
              </ApplicationLayout>
            )}
          />
          <Route
            exact
            path="/spells/edit"
            element={(
              <ApplicationLayout>
                <SpellCreator />
              </ApplicationLayout>
            )}
          />
          <Route
            exact
            path="/decks"
            element={(
              <ApplicationLayout>
                <Decks />
              </ApplicationLayout>
            )}
          />
          <Route
            exact
            path="/decks/edit"
            element={(
              <ApplicationLayout>
                <DeckCreator />
              </ApplicationLayout>
            )}
          />
          <Route
            exact
            path="/families"
            element={(
              <ApplicationLayout>
                <Families />
              </ApplicationLayout>
            )}
          />
          <Route
            exact
            path="/families/edit"
            element={(
              <ApplicationLayout>
                <FamilyCreator />
              </ApplicationLayout>
            )}
          />
          <Route
            exact
            path="/mobs"
            element={(
              <ApplicationLayout>
                <Mobs />
              </ApplicationLayout>
            )}
          />
          <Route
            exact
            path="/mobs/edit"
            element={(
              <ApplicationLayout>
                <MobCreator />
              </ApplicationLayout>
            )}
          />
          <Route
            exact
            path="/maps"
            element={(
              <ApplicationLayout>
                <BattleMaps />
              </ApplicationLayout>
            )}
          />
          <Route
            exact
            path="/maps/edit"
            element={(
              <ApplicationLayout>
                <BattleMapCreator />
              </ApplicationLayout>
            )}
          />
          <Route
            exact
            path="/battles"
            element={(
              <ApplicationLayout>
                <Battles />
              </ApplicationLayout>
            )}
          />
          <Route
            exact
            path="/battles/edit"
            element={(
              <ApplicationLayout>
                <BattleCreator />
              </ApplicationLayout>
            )}
          />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
