import Radar from './Radar'

// const elementToColor = {
//   Fire: 'crimson',
//   Plant: 'limegreen',
//   Water: 'deepskyblue',
//   Air: 'khaki',
//   Earth: 'saddlebrown',
//   Ether: 'purple',
// }

const elementToSymbol = {
  Fire: '🔥',
  Ether: '🧠',
  Plant: '🌱',
  Air: '🌀',
  Water: '💧',
  Earth: '🗿',
}

const defaultData = Object.keys(elementToSymbol).reduce((acc, element) => ({ ...acc, [element]: { value: 0, symbol: elementToSymbol[element] } }), {})

function ElementsRadar({ data, size, ...props }) {
  const finalData = { ...defaultData }

  Object.entries(data).forEach(([element, value]) => {
    finalData[element].value = value
  })

  return (
    <Radar
      data={finalData}
      size={size}
      {...props}
    />
  )
}

export default ElementsRadar
