import { useCallback, useEffect, useMemo, useState } from 'react'
import { collection, deleteDoc, doc, getDocs, setDoc } from 'firebase/firestore'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography, useTheme } from '@mui/material'
import { DeleteOutlined, EditOutlined } from '@mui/icons-material'

import { db } from '../firebase'
import useDebounce from '../hooks/useDebounce'

const defaultSort = (a, b) => a.createdAt < b.createdAt ? -1 : 1

function JsonAdministrator({
  title,
  itemName,
  collectionName,
  routeName,
  renderItem,
  itemClassname = 'y8',
  localStorageKey,
  sortFunction = defaultSort,
  nItemsPerRow = 6,
  setRefetch = () => null,
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [items, setItems] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [search, setSearch] = useState('')
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false)
  const theme = useTheme()
  const navigate = useNavigate()

  const debouncedSearch = useDebounce(search, 200)

  const foundItems = useMemo(() => (
    items.map(x => {
      const y = { ...x }

      delete y.id
      delete y.createdAt

      return { object: x, serialized: JSON.stringify(y).toLowerCase() }
    }).filter(x => x.serialized.includes(debouncedSearch.toLowerCase())).map(x => x.object)
  ), [items, debouncedSearch])

  const fetchData = useCallback(async () => {
    setItems([])

    const fetchedItems = []
    const querySnapshot = await getDocs(collection(db, collectionName))

    querySnapshot.forEach(doc => {
      fetchedItems.push(doc.data())
    })

    setItems(fetchedItems.sort(sortFunction))
    setIsLoading(false)
  }, [collectionName, sortFunction])

  useEffect(fetchData, [fetchData])
  useEffect(() => setRefetch(() => fetchData), [setRefetch, fetchData])

  function handleEdit(item) {
    localStorage.setItem(localStorageKey, JSON.stringify(item))
    navigate(`/${routeName}/edit`)
  }

  async function handleDelete(item) {
    if (!window.confirm(`Are you sure you want to delete ${item.name}?`)) return

    await deleteDoc(doc(db, collectionName, item.id))
    await fetchData()
  }

  async function handleDeleteSelected() {
    if (!window.confirm(`Are you sure you want to delete ${selectedIds.length} items?`)) return

    await Promise.all(selectedIds.map(id => deleteDoc(doc(db, collectionName, id))))

    setSelectedIds([])

    await fetchData()
  }

  function toggleSelected(item) {
    const nextSelectedIds = selectedIds.slice()

    if (selectedIds.includes(item.id)) {
      nextSelectedIds.splice(nextSelectedIds.indexOf(item.id), 1)
    }
    else {
      nextSelectedIds.push(item.id)
    }

    setSelectedIds(nextSelectedIds)
  }

  function handleSelectAll() {
    if (selectedIds.length < items.length) {
      setSelectedIds(items.map(item => item.id))
    }
    else {
      setSelectedIds([])
    }
  }

  async function handleExport() {
    const selectedItems = selectedIds.map(id => items.find(item => item.id === id))

    await navigator.clipboard.writeText(JSON.stringify(selectedItems, null, 2))

    window.alert('Exported! Data copied to clipboard.')
  }

  async function handleImport(data) {
    if (!data) return

    try {
      const items = JSON.parse(data)

      await Promise.all(
        items.map(item => setDoc(doc(db, collectionName, item.id), item))
      )
      await fetchData()
    }
    catch (error) {
      window.alert('Invalid JSON data.')
    }
  }

  const workItems = debouncedSearch.length ? foundItems : items

  return (
    <>
      <Typography variant="h3">
        {title}
      </Typography>
      <div className="mt-4 x4">
        <Button
          variant="contained"
          component={RouterLink}
          to={`/${routeName}/edit?new=1`}
        >
          New {itemName}
        </Button>
        <Button
          variant="contained"
          component={RouterLink}
          to={`/${routeName}/edit`}
          className="ml-2"
        >
          Continue {itemName}
        </Button>
        <Button
          variant="contained"
          onClick={handleSelectAll}
          className="ml-2"
        >
          Select all
        </Button>
        <Button
          variant="contained"
          onClick={() => setIsImportDialogOpen(true)}
          className="ml-2"
        >
          Import
        </Button>
        <Button
          variant="contained"
          onClick={handleExport}
          className="ml-2"
          disabled={selectedIds.length === 0}
        >
          Export selected
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleDeleteSelected}
          className="ml-2"
          disabled={selectedIds.length === 0}
        >
          Delete selected
        </Button>
        <TextField
          value={search}
          onChange={event => setSearch(event.target.value)}
          variant="outlined"
          placeholder="Search"
          size="small"
          className="ml-2"
          InputProps={{ style: { maxHeight: 36.5 } }}
        />
      </div>
      <div className="mt-4 x11">
        {isLoading && (
          <div className="x5 w100">
            <CircularProgress color="primary" />
          </div>
        )}
        {workItems.map(item => (
          <div
            key={item.name}
            className={`${itemClassname} mb-2 mr-2`}
            style={{
              width: `calc(100% / ${nItemsPerRow} - 16px)`,
              borderColor: selectedIds.includes(item.id) ? theme.palette.primary.main : 'transparent',
              borderWidth: 1,
              borderStyle: 'solid',
              borderRadius: 4,
            }}
            onClick={() => toggleSelected(item)}
          >
            <div className="mt-1">
              {renderItem(item)}
            </div>
            <div className="x5 mt-1">
              <IconButton
                onClick={() => handleEdit(item)}
              >
                <EditOutlined />
              </IconButton>
              <IconButton
                onClick={() => handleDelete(item)}
                className="ml-2"
              >
                <DeleteOutlined />
              </IconButton>
            </div>
          </div>
        ))}
        {!isLoading && workItems.length === 0 && (
          <div className="x5 w100">
            <Typography>No items</Typography>
          </div>
        )}
      </div>
      <ImportDialog
        open={isImportDialogOpen}
        onClose={() => setIsImportDialogOpen(false)}
        onValidate={handleImport}
      />
    </>
  )
}

function ImportDialog({ open, onClose, onValidate }) {
  const [value, setValue] = useState('')

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Import</DialogTitle>
      <DialogContent className="pt-1">
        <TextField
          variant="outlined"
          autoFocus
          label="JSON data"
          type="text"
          fullWidth
          value={value}
          multiline
          rows={12}
          onChange={event => setValue(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => {
            onValidate(value)
            onClose()
          }}
        >
          Import
        </Button>
      </DialogActions>
    </Dialog>

  )
}

export default JsonAdministrator
