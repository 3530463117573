import './ZoneCreator.css'

import { useEffect, useState } from 'react'

import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

function ZoneCreator({ value, onChange }) {
  const [rows, setRows] = useState(value.zoneX || 3)
  const [columns, setColumns] = useState(value.zoneY || 3)
  const [tool, setTool] = useState('1')
  const [cells, setCells] = useState(value.zone || createCells(rows, columns))

  useEffect(() => {
    onChange({
      zoneX: rows,
      zoneZ: columns,
      zone: cells.flat(),
    })
  }, [onChange, rows, columns, cells])

  function createCells(rows, columns, existingCells = []) {
    const nextCells = existingCells.map(row => row.slice())

    if (nextCells.length > rows) {
      const l = nextCells.length

      for (let y = 0; y < l - rows; y++) {
        nextCells.pop()
      }
    }

    if (nextCells[0] && nextCells[0].length > columns) {
      nextCells.forEach(row => {
        const l = row.length

        for (let x = 0; x < l - columns; x++) {
          row.pop()
        }
      })
    }

    if (nextCells.length < rows) {
      for (let y = nextCells.length; y < rows; y++) {
        const row = []

        for (let x = 0; x < columns; x++) {
          row.push(0)
        }

        nextCells.push(row)
      }
    }

    if (nextCells[0] && nextCells[0].length < columns) {
      nextCells.forEach(row => {
        for (let x = row.length; x < columns; x++) {
          row.push(0)
        }
      })
    }

    return nextCells
  }

  function applyTool({ row, column }) {
    const nextCells = cells.map(row => row.slice())

    switch (tool) {
      case '0': {
        nextCells[row][column] = 0
        break
      }

      case '1': {
        nextCells[row][column] = nextCells[row][column] === 2 ? 3 : 1
        break
      }

      case '2': {
        nextCells[row][column] = nextCells[row][column] === 1 ? 3 : 2
        break
      }
    }

    setCells(nextCells)
  }

  function handleTrimZoneClick() {
    const nextCells = cells.map(row => row.slice())

    for (let i = 0; i < nextCells.length; i++) {
      let isEmpty = true

      for (let j = 0; j < nextCells[0].length; j++) {
        if (nextCells[i][j]) isEmpty = false
      }

      if (isEmpty) {
        nextCells.splice(i, 1)
        i -= 1
      }
    }

    if (!nextCells[0]) return

    let l = nextCells[0].length

    for (let i = 0; i < l; i++) {
      let isEmpty = true

      for (let j = 0; j < nextCells.length; j++) {
        if (nextCells[j][i]) isEmpty = false
      }

      if (isEmpty) {
        for (let j = 0; j < nextCells.length; j++) {
          nextCells[j].splice(i, 1)
        }

        l -= 1
        i -= 1
      }
    }

    setRows(nextCells.length)
    setColumns(nextCells[0].length)
    setCells(nextCells)
  }

  function handleExportZoneClick() {
    navigator.clipboard.writeText(JSON.stringify(value))
    .then(() => {
      window.alert('Zone copied to clipboard!')
    })
  }

  function renderDimensions() {
    return (
      <div>
        <TextField
          value={rows}
          onChange={event => {
            const rows = event.target.value

            setRows(rows)
            setCells(cells => createCells(rows, columns, cells))
          }}
          type="number"
          label="Rows"
          variant="outlined"
          size="small"
          inputProps={{
            min: 1,
          }}
          style={{
            width: 128 - 32 + 8,
            display: 'block',
          }}
        />
        <TextField
          value={columns}
          onChange={event => {
            const columns = event.target.value

            setColumns(columns)
            setCells(cells => createCells(rows, columns, cells))
          }}
          type="number"
          label="Columns"
          variant="outlined"
          size="small"
          inputProps={{
            min: 1,
          }}
          className="mt-2"
          style={{
            width: 128 - 32 + 8,
            display: 'block',
          }}
        />
      </div>
    )
  }

  function renderTools() {
    return (
      <div>
        <Typography variant="caption">
          Tool
        </Typography>
        <RadioGroup
          value={tool}
          onChange={event => setTool(event.target.value)}
        >
          <FormControlLabel
            value="1"
            control={<Radio />}
            label="Start"
          />
          <FormControlLabel
            value="2"
            control={<Radio />}
            label="Zone"
          />
          <FormControlLabel
            value="0"
            control={<Radio />}
            label="Empty"
          />
        </RadioGroup>
      </div>
    )
  }

  function renderCells() {
    return (
      <div className="ZoneCreator-cells">
        {cells.map((row, j) => (
          <div
            key={j}
            className="x4"
          >
            {row.map((cell, i) => (
              <Typography
                key={`${i}_${j}`}
                onClick={() => applyTool({ row: j, column: i })}
                className="x5 no-select cursor-pointer ZoneCreator-cell"
                style={{
                  color: 'DarkBlue',
                  backgroundColor: cell === 2 || cell === 3 ? 'crimson' : 'transparent',
                }}
              >
                {cell === 1 || cell === 3 ? 'S' : ''}
              </Typography>
            ))}
          </div>
        ))}
      </div>
    )
  }

  function renderInfo() {
    const flatCells = cells.flat(1)

    const isZoneEmpty = flatCells.every(x => !x)

    if (isZoneEmpty) {
      return (
        <Typography variant="caption">
          Zone is empty
        </Typography>
      )
    }

    const nStart = flatCells.reduce((sum, cell) => sum += cell === 1 || cell === 3 ? 1 : 0, 0)

    if (nStart === 0) {
      return (
        <Typography
          variant="caption"
          style={{ color: 'crimson' }}
        >
          Zone has no start cell
        </Typography>
      )
    }

    if (nStart > 1) {
      return (
        <Typography
          variant="caption"
          style={{ color: 'crimson' }}
        >
          Zone has more than one start cell
        </Typography>
      )
    }

    const nZone = flatCells.reduce((sum, cell) => sum += cell === 2 || cell === 3 ? 1 : 0, 0)

    if (nZone === 0) {
      return (
        <Typography
          variant="caption"
          style={{ color: 'crimson' }}
        >
          Zone need area of effect
        </Typography>
      )
    }

    return (
      <Typography
        variant="caption"
        style={{ color: 'YellowGreen' }}
      >
        Zone is valid
      </Typography>
    )
  }

  return (
    <div className="x11">
      <div>
        {renderTools()}
        <div className="mt-2">
          {renderDimensions()}
        </div>
      </div>
      <div className="ml-2">
        {renderCells()}
      </div>
      <div className="ml-2 y1">
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleTrimZoneClick}
        >
          Trim zone
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className="mt-1"
          onClick={handleExportZoneClick}
        >
          Export zone
        </Button>
        <div className="mt-2">
          {renderInfo()}
        </div>
        <div className="x11 mt-2">
          <ArrowDownwardIcon />
          <Typography className="ml-0h">
            Character orientation
          </Typography>
        </div>

      </div>
    </div>
  )
}

export default ZoneCreator
