import JsonAdministrator from '../../components/JsonAdministrator'
import DeckPreview from '../../components/DeckPreview'

import { mobCreatorLocalStorageKey } from '../../configuration'

function Mobs() {
  return (
    <JsonAdministrator
      title="Decks"
      itemName="Deck"
      collectionName="decks"
      routeName="decks"
      localStorageKey={mobCreatorLocalStorageKey}
      nItemsPerRow={1}
      renderItem={deck => (
        <DeckPreview
          deck={deck}
        />
      )}
    />
  )
}

export default Mobs
