import JsonCreator from '../../components/JsonCreator'

import { familyCreatorLocalStorageKey } from '../../configuration'

import schema from '../../schemas/family'

function FamilyCreator() {
  return (
    <JsonCreator
      title="Family Creator"
      schema={schema}
      collectionName="families"
      localStorageKey={familyCreatorLocalStorageKey}
    />
  )
}

FamilyCreator.schema = schema

export default FamilyCreator
