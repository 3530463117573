import { useCallback, useEffect, useState } from 'react'

import {
  Button,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'

import BattleMapPreview from './BattleMapPreview'

// Cells be like
// ['1', '0', 'h'] cost, elevation, startingPosiition
const basicCell = '10-'
const initialSize = 9

function CellsCreator({ value, onChange }) {
  const [rows, setRows] = useState(value.rows || initialSize)
  const [columns, setColumns] = useState(value.columns || initialSize)
  const [cells, setCells] = useState(value.cells || createCells(rows, columns))
  const [tool, setTool] = useState(null)

  const resetCells = useCallback(() => {
    setRows(initialSize)
    setColumns(initialSize)
    setCells(createCells(initialSize, initialSize))
  }, [])

  useEffect(() => {
    onChange({
      rows: parseInt(rows),
      columns: parseInt(columns),
      cells,
    })
  }, [onChange, cells, rows, columns])

  function handleResetClick() {
    if (window.confirm('This will reset all cells in the BattleMap')) {
      resetCells()
    }
  }

  function createCells(rows, columns, existingCells = []) {
    const nextCells = existingCells.map(row => row.slice())

    if (nextCells.length > rows) {
      const l = nextCells.length

      for (let y = 0; y < l - rows; y++) {
        nextCells.pop()
      }
    }

    if (nextCells[0] && nextCells[0].length > columns) {
      nextCells.forEach(row => {
        const l = row.length

        for (let x = 0; x < l - columns; x++) {
          row.pop()
        }
      })
    }

    if (nextCells.length < rows) {
      for (let y = nextCells.length; y < rows; y++) {
        const row = []

        for (let x = 0; x < columns; x++) {
          row.push(basicCell)
        }

        nextCells.push(row)
      }
    }

    if (nextCells[0] && nextCells[0].length < columns) {
      nextCells.forEach(row => {
        for (let x = row.length; x < columns; x++) {
          row.push(basicCell)
        }
      })
    }

    return nextCells.flat().join('')
  }

  function unflat(cells) {
    const rows = []

    for (let i = 0; i < cells.length; i += 3 * columns) {
      rows.push(splitBy3(cells.slice(i, i + 3 * columns)))
    }

    return rows
  }

  function splitBy3(string) {
    const array = []

    for (let i = 0; i < string.length; i += 3) {
      array.push(string.slice(i, i + 3))
    }

    return array
  }

  function setCurrentCellFactory(key) {
    return (position, value) => {
      setCells(cells.slice(0, 3 * (position.row * columns + position.column) + key) + value + cells.slice(3 * (position.row * columns + position.column) + key + 1))
    }
  }

  const setCurrentCellCost = setCurrentCellFactory(0)
  const setCurrentCellElevation = setCurrentCellFactory(1)
  const setCurrentCellStartingPosition = setCurrentCellFactory(2)

  function applyTool(position) {
    switch (tool) {
      case 'grass': {
        setCurrentCellCost(position, '1')
        break
      }

      case 'sand': {
        setCurrentCellCost(position, '2')
        break
      }

      case 'stone': {
        setCurrentCellCost(position, '3')
        break
      }

      case 'high': {
        setCurrentCellElevation(position, 'h')
        break
      }

      case 'low': {
        setCurrentCellElevation(position, 'l')
        break
      }

      case 'none': {
        setCurrentCellElevation(position, 'x')
        break
      }

      case 'elevation-0': {
        setCurrentCellElevation(position, '0')
        break
      }

      case 'elevation-1': {
        setCurrentCellElevation(position, '1')
        break
      }

      case 'elevation-2': {
        setCurrentCellElevation(position, '2')
        break
      }

      case 'hero': {
        setCurrentCellStartingPosition(position, 'h')
        break
      }

      case 'mob': {
        setCurrentCellStartingPosition(position, 'm')
        break
      }

      default: {
        setCurrentCellCost(position, '1')
        setCurrentCellElevation(position, '0')
        setCurrentCellStartingPosition(position, '-')
      }
    }
  }

  function renderControls() {
    return (
      <div className="x41 w100">
        <TextField
          value={rows}
          onChange={event => {
            const rows = parseInt(event.target.value)

            if (rows !== rows) return

            setRows(rows)
            setCells(cells => createCells(rows, columns, unflat(cells)))
          }}
          type="number"
          inputProps={{
            min: 1,
          }}
          size="small"
          style={{ width: 64 }}
        />
        <Typography className="mx-1">
          x
        </Typography>
        <TextField
          value={columns}
          onChange={event => {
            const columns = parseInt(event.target.value)

            if (columns !== columns) return

            setColumns(columns)
            setCells(cells => createCells(rows, columns, unflat(cells)))
          }}
          type="number"
          inputProps={{
            min: 1,
          }}
          size="small"
          style={{ width: 64 }}
        />
        <Button
          className="ml-2"
          variant="contained"
          onClick={handleResetClick}
          style={{ width: 76 }}
        >
          Reset
        </Button>
      </div>
    )
  }

  function renderTools() {
    return (
      <ToggleButtonGroup
        value={tool}
        onChange={event => setTool(event.target.value)}
        variant="text"
      >
        <ToggleButton
          value="grass"
        >
          grass
        </ToggleButton>
        <ToggleButton
          value="sand"
        >
          sand
        </ToggleButton>
        <ToggleButton
          value="stone"
        >
          stone
        </ToggleButton>
        <ToggleButton
          value="elevation-0"
        >
          elevation 0
        </ToggleButton>
        <ToggleButton
          value="elevation-1"
        >
          elevation 1
        </ToggleButton>
        <ToggleButton
          value="elevation-2"
        >
          elevation 2
        </ToggleButton>
        <ToggleButton
          value="high"
        >
          high
        </ToggleButton>
        <ToggleButton
          value="low"
        >
          low
        </ToggleButton>
        <ToggleButton
          value="none"
        >
          none
        </ToggleButton>
        <ToggleButton
          value="hero"
        >
          hero
        </ToggleButton>
        <ToggleButton
          value="mob"
        >
          mob
        </ToggleButton>
        <ToggleButton
          value="reset"
        >
          reset
        </ToggleButton>
      </ToggleButtonGroup>
    )
  }

  return (
    <>
      <div>
        {renderControls()}
      </div>
      <div className="mt-2">
        {renderTools()}
      </div>
      <div className="mt-2 x11 w100">
        <BattleMapPreview
          battleMap={{ rows, columns, cells }}
          onClick={applyTool}
        />
      </div>
    </>
  )
}

export default CellsCreator
