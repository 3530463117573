import Radar from './Radar'

const mechanicToSymbol = {
  Melee: '⚔️',
  Distance: '🏹',
  Movement: '➡️',
  Invocation: '🐈',
  Card: '🃏',
  Buff: '💡',
  Healing: '🧪',
  Defense: '🛡',
}

const defaultData = Object.keys(mechanicToSymbol).reduce((acc, mechanics) => ({ ...acc, [mechanics]: { value: 0, symbol: mechanicToSymbol[mechanics] } }), {})

function MechanicsRadar({ data, size, ...props }) {
  const finalData = { ...defaultData }

  Object.entries(data).forEach(([element, value]) => {
    finalData[element].value = value
  })

  return (
    <Radar
      data={finalData}
      size={size}
      {...props}
    />
  )
}

export default MechanicsRadar
