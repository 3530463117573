import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore'
import { nanoid } from 'nanoid'

import { db } from '../firebase'

import ZoneCreator from './ZoneCreator'
import CellsCreator from './CellsCreator'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 256,
    },
  },
}

function JsonCreator({
  title,
  schema,
  collectionName,
  localStorageKey,
  Informations = () => null,
}) {
  const createObj = useCallback(() => {
    const newObj = {}

    Object.entries(schema).forEach(([key, value]) => {
      if (typeof value.default !== 'undefined') {
        newObj[key] = value.default
      }
    })

    return newObj
  }, [schema])

  const [searchParams, setSearchParams] = useSearchParams()
  const [obj, setObj] = useState(searchParams.get('new') ? createObj() : (importObj() || createObj()))
  const [parameters, setParameters] = useState({})
  const [areResultsExpanded, setAreResultExpanded] = useState(false)
  const theme = useTheme()

  const onMultipleChange = useCallback(data => {
    setObj(obj => ({ ...obj, ...data }))
  }, [])

  const resetObj = useCallback(() => {
    setObj(createObj())
  }, [createObj])

  const exportObj = useCallback(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(obj))
  }, [obj, localStorageKey])

  function importObj() {
    const jsonObj = localStorage.getItem(localStorageKey)

    return jsonObj ? JSON.parse(jsonObj) : null
  }

  useEffect(() => {
    setSearchParams({}, { replace: true })
  }, [setSearchParams])

  useEffect(() => {
    exportObj(obj)
  }, [exportObj, obj])

  // Clean unused properties
  useEffect(() => {
    let changed = false
    const nextObj = { ...obj }

    if (!parameters.hasZone && obj.zone) {
      delete nextObj.zone
      delete nextObj.zoneX
      delete nextObj.zoneZ

      changed = true
    }

    Object.entries(nextObj).forEach(([key, value]) => {
      if (value === '' || value === false) {
        delete nextObj[key]

        changed = true
      }
    })

    if (changed) {
      setObj(nextObj)
    }
  }, [parameters, obj])

  useEffect(() => {
    document.addEventListener('wheel', () => {
      if (document.activeElement.type === 'number') {
        document.activeElement.blur()
      }
    })
  }, [])

  async function handleSaveClick() {
    const docRef = doc(db, collectionName, obj.id)
    const docSnapshot = await getDoc(docRef)

    if (docSnapshot.exists() && !window.confirm(`A ${collectionName} with this id already exists, overwrite?`)) {
      return
    }

    await setDoc(docRef, obj)
    await navigator.clipboard.writeText(JSON.stringify(obj, null, 2))

    window.alert('Saved! Data copied to clipboard.')
  }

  function handleResetClick() {
    if (window.confirm('This will reset the obj to its default state. Are you sure?')) {
      resetObj()
    }
  }

  function skipUndefined(value) {
    return typeof value === 'undefined' ? '' : value
  }

  function renderSchemaObject(schema, getter, setter) {
    return (
      <div>
        {Object.entries(schema).map(([key, value]) => {
          const itemGetter = typeof getter === 'function' ? (() => (getter() || {})[key]) : (() => obj[key])
          const itemSetter = typeof setter === 'function' ? ((value, fixedKey) => setter(value, fixedKey || key)) : ((value, fixedKey) => setObj(obj => ({ ...obj, [fixedKey || key]: value })))

          return (
            <div
              key={key}
              className="mb-2"
            >
              {renderSchemaItem(itemGetter, itemSetter, key, value)}
            </div>
          )
        })}
      </div>
    )
  }

  function renderSchemaItem(getter, setter, key, properties) {
    return (
      <div className="mb-1 x11">
        <Typography
          title={properties.description}
          className="x4 cursor-help"
          style={{ minWidth: 128 + 64 + 32 }}
        >
          {key}:
        </Typography>
        <div className="y1">
          {renderSchemaNode(getter, setter, key, properties)}
        </div>
      </div>
    )
  }

  function renderSchemaNode(getter, setter, key, properties) {
    let node = null

    if (properties.collectionName) {
      node = renderSchemaFetchable(getter, setter, key, properties)
    }
    else {
      switch (properties.type) {
        case 'id': {
          node = renderSchemaText(getter, setter, key, properties, false, true)
          break
        }
        case 'text': {
          node = renderSchemaText(getter, setter, key, properties, false)
          break
        }
        case 'textarea': {
          node = renderSchemaText(getter, setter, key, properties, true)
          break
        }
        case 'now': {
          node = renderSchemaText(getter, setter, key, properties, false, false, true)
          break
        }
        case 'integer': {
          node = renderSchemaNumber(getter, setter, key, properties, true)
          break
        }
        case 'float': {
          node = renderSchemaNumber(getter, setter, key, properties, false)
          break
        }
        case 'image': {
          node = renderSchemaImage(getter, setter, key, properties)
          break
        }
        case 'prefab': {
          node = renderSchemaImage(getter, setter, key, properties, 'prefab')
          break
        }
        case 'minmaxcritical': {
          node = renderSchemaMinMaxCritical(getter, setter, key, properties)
          break
        }
        case 'boolean': {
          node = renderSchemaBoolean(getter, setter, key, properties)
          break
        }
        case 'multiselect': {
          node = renderSchemaMultiselect(getter, setter, key, properties)
          break
        }
        case 'zone': {
          const zoneGetter = () => obj // For now at root

          node = renderSchemaZone(zoneGetter, setter, key, properties)
          break
        }
        case 'cells': {
          const cellsGetter = () => obj // For now at root

          node = renderSchemaCells(cellsGetter, setter, key, properties)
          break
        }
        case 'list': {
          node = renderSchemaList(getter, setter, key, properties)
          break
        }
        case 'object': {
          const objSetter = (value, fixedKey) => setter({ ...(getter() || {}), [fixedKey || key]: value })

          node = renderSchemaObject(properties.schema, getter, objSetter)
          break
        }
      }
    }

    return node
  }

  function renderSchemaFetchable(getter, setter, key, properties) {
    const handleOpen = () => setParameters(parameters => ({ ...parameters, [key]: true }))

    if (getter()) {
      return (
        <>
          <div>
            {renderSchemaText(getter, setter, key, properties, false, true)}
          </div>
          <div
            onClick={handleOpen}
            className="mt-1"
          >
            {properties.renderItem(getter())}
          </div>
          {renderSchemaFetchableDialog(getter, setter, key, properties)}
        </>
      )
    }

    return (
      <>
        <Typography
          onClick={handleOpen}
          className="cursor-pointer"
        >
          Choose {properties.type}
        </Typography>
        {renderSchemaFetchableDialog(getter, setter, key, properties)}
      </>
    )
  }

  function renderSchemaText(getter, setter, key, properties, isTextarea, isId, isNow) {
    if (isId && !getter()) {
      setter(nanoid())
    }

    if (isNow && !getter()) {
      setter(new Date().toISOString())
    }

    return (
      <TextField
        value={skipUndefined(getter())}
        onChange={event => setter(event.target.value)}
        placeholder={key}
        type="text"
        variant="outlined"
        size="small"
        multiline={isTextarea}
        disabled={isId || isNow || properties.isReadOnly}
        rows={isTextarea ? 6 : 1}
        style={{ width: 256 }}
      />
    )
  }

  function renderSchemaNumber(getter, setter, key, properties, isInteger) {
    return (
      <TextField
        value={skipUndefined(getter())}
        onChange={event => {
          const value = isInteger ? parseInt(event.target.value) : parseFloat(event.target.value)

          setter(value !== value ? event.target.value : value)
        }}
        placeholder={key}
        type="number"
        variant="outlined"
        size="small"
        inputProps={isInteger ? ({ step: 1 }) : {}}
        style={{ width: 256 }}
      />
    )
  }

  function renderSchemaBoolean(getter, setter) {
    return (
      <Checkbox
        value={getter()}
        onChange={event => setter(event.target.checked)}
        style={{ marginLeft: -12 }}
      />
    )
  }

  function renderSchemaImage(getter, setter, key, properties, name = 'image') {
    const images = properties.names.map(name => ({ name, src: `/${properties.location}/${name}` }))
    const handleOpen = () => setParameters(parameters => ({ ...parameters, [key]: true }))

    if (getter()) {
      return (
        <>
          <img
            src={`/${properties.location}/${getter()}.png`}
            width={64 + 32 + 16 + 8 + 4 + 2 + 1}
            onClick={handleOpen}
            className="cursor-pointer"
          />
          {renderSchemaImageDialog(getter, setter, key, properties, images)}
        </>
      )
    }

    return (
      <>
        <Typography
          onClick={handleOpen}
          className="cursor-pointer"
        >
          Choose {name}
        </Typography>
        {renderSchemaImageDialog(getter, setter, key, properties, images)}
      </>
    )
  }

  function renderSchemaMinMaxCritical(getter, setter, key) {
    return (
      <div className="x1">
        <TextField
          value={skipUndefined(obj[`min${key}`])}
          onChange={event => setter(parseInt(event.target.value), `min${key}`)}
          placeholder={`min${key}`}
          type="number"
          variant="outlined"
          size="small"
          style={{ width: 256 - 64 }}
        />
        <TextField
          value={skipUndefined(obj[`max${key}`])}
          onChange={event => setter(parseInt(event.target.value), `max${key}`)}
          placeholder={`max${key}`}
          type="number"
          variant="outlined"
          size="small"
          className="ml-2"
          style={{ width: 256 - 64 }}
        />
        <TextField
          value={skipUndefined(obj[`minCritical${key}`])}
          onChange={event => setter(parseInt(event.target.value), `minCritical${key}`)}
          placeholder={`minCritical${key}`}
          type="number"
          variant="outlined"
          size="small"
          className="ml-2"
          style={{ width: 256 - 64 }}
        />
        <TextField
          value={skipUndefined(obj[`maxCritical${key}`])}
          onChange={event => setter(parseInt(event.target.value), `maxCritical${key}`)}
          placeholder={`maxCritical${key}`}
          type="number"
          variant="outlined"
          size="small"
          className="ml-2"
          style={{ width: 256 - 64 }}
        />
      </div>
    )
  }

  function renderSchemaMultiselect(getter, setter, key, properties) {
    return (
      <FormControl style={{ minWidth: 256 }}>
        <InputLabel id={key}>
          {key}
        </InputLabel>
        <Select
          multiple
          labelId={key}
          value={getter() || []}
          onChange={event => setter(event.target.value)}
          input={<Input />}
          MenuProps={MenuProps}
        >
          {properties.values.map(value => (
            <MenuItem
              key={value}
              value={value}
            >
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  function renderSchemaList(getter, setter, key, properties) {
    const nodes = []
    const nKey = `n_${key}`
    const list = getter() || []
    const n = Math.max(list.length, parameters[nKey] || 0)

    for (let i = 0; i < n; i++) {
      const nodeGetter = () => list[i]
      const nodeSetter = value => {
        const obj = list.slice()

        obj[i] = value

        setter(obj)
      }
      const nodeEraser = () => {
        setParameters(parameters => ({ ...parameters, [nKey]: n - 1 }))
        setter(list.filter((_, index) => index !== i))
      }

      nodes.push(
        <div
          className="x1 mt-2"
          key={i}
        >
          <div>
            <Typography>
              {i}:
            </Typography>
            <Button
              size="small"
              variant="contained"
              onClick={nodeEraser}
              className="mt-1"
            >
              -
            </Button>
          </div>
          <div
            className="ml-2 p-2 border-radius-4"
            style={{ border: `1px solid ${theme.palette.divider}` }}
          >
            {renderSchemaNode(nodeGetter, nodeSetter, `${key}_${i}`, properties.schema)}
          </div>
        </div>
      )
    }

    return (
      <div>
        {!!nodes.length && (
          <div className="mb-2">
            {nodes}
          </div>
        )}
        <div>
          <Button
            size="small"
            variant="contained"
            onClick={() => setParameters(parameters => ({ ...parameters, [nKey]: n + 1 }))}
          >
            +
          </Button>
        </div>
      </div>
    )
  }

  function renderSchemaZone(getter) {
    const hasZoneCheckbox = (
      <FormControlLabel
        control={(
          <Checkbox
            checked={!!parameters.hasZone}
            onChange={event => setParameters(parameters => ({ ...parameters, hasZone: event.target.checked }))}
          />
        )}
        label="Enable zone"
      />
    )

    if (!parameters.hasZone) return hasZoneCheckbox

    console.log('getter()', getter())

    return (
      <div className="x11">
        <div>
          {hasZoneCheckbox}
        </div>
        <div className="ml-2">
          <ZoneCreator
            value={getter()}
            onChange={onMultipleChange}
          />
        </div>
      </div>
    )
  }

  function renderSchemaCells(getter) {
    return (
      <CellsCreator
        value={getter()}
        onChange={onMultipleChange}
      />
    )
  }

  function renderSchemaImageDialog(getter, setter, key, properties, images) {
    const handleClose = () => setParameters(parameters => ({ ...parameters, [key]: false }))

    function handleImageClick(name) {
      setter(name.slice(0, -4)) // Remove .png
      handleClose()
    }

    return (
      <Dialog
        open={parameters[key] || false}
        onClose={handleClose}
        maxWidth="lg"
      >
        <div className="p-2 x11">
          {images.map(({ name, src }) => (
            <div
              className="mb-1 mr-1"
              key={src}
            >
              <img
                key={name}
                width={64 + 32 + 16 + 8 + 4 + 2 + 1}
                src={src}
                className="mr-1 mb-1 cursor-pointer"
                onClick={() => handleImageClick(name)}
              />
              {properties.shouldDisplayName && (
                <Typography
                  fontSize={8}
                  maxWidth={64 + 32 + 16 + 8 + 4 + 2 + 1}
                  noWrap
                  title={name}
                  style={{ marginTop: -8 }}
                >
                  {name}
                </Typography>
              )}
            </div>
          ))}
        </div>
      </Dialog>
    )
  }

  function renderSchemaFetchableDialog(getter, setter, key, properties) {
    const handleClose = () => setParameters(parameters => ({ ...parameters, [key]: false }))

    return (
      <FetchableDialog
        open={parameters[key] || false}
        onClose={handleClose}
        onValidate={value => {
          setter(value)
          handleClose()
        }}
        collectionName={properties.collectionName}
        renderItem={properties.renderItem}
        shouldModalDisplayRows={properties.shouldModalDisplayRows}
      />
    )
  }

  function renderResult() {
    return (
      <>

        <Typography
          variant="caption"
          className="cursor-pointer no-select"
          onClick={() => setAreResultExpanded(x => !x)}
        >
          {areResultsExpanded ? 'Hide results' : 'Show results'}
        </Typography>
        <div
          className="overflow-hidden"
          style={{ height: areResultsExpanded ? 'auto' : 0 }}
        >
          <pre style={{ maxWidth: '100%' }}>
            {JSON.stringify(obj, null, 2)}
          </pre>
        </div>
        <Informations data={obj} />
        <div className="x4 mt-2">
          <Button
            variant="contained"
            onClick={handleSaveClick}
          >
            Save
          </Button>
          <Button
            variant="contained"
            onClick={handleResetClick}
            className="ml-2"
          >
            Reset
          </Button>
        </div>
      </>
    )
  }

  return (
    <>
      <Typography
        variant="h3"
      >
        {title}
      </Typography>
      <div className="mt-4">
        {renderSchemaObject(schema)}
      </div>
      <div className="mt-4">
        {renderResult()}
      </div>
    </>
  )
}

function FetchableDialog({
  open,
  onClose,
  onValidate,
  collectionName,
  renderItem,
  shouldModalDisplayRows,
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [items, setItems] = useState([])

  const fetchData = useCallback(async () => {
    const fetchedItems = []
    const querySnapshot = await getDocs(collection(db, collectionName))

    querySnapshot.forEach(doc => {
      fetchedItems.push(doc.data())
    })

    setItems(fetchedItems.sort((a, b) => b.createdAt - a.createdAt))
    setIsLoading(false)
  }, [collectionName])

  useEffect(fetchData, [fetchData])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
    >
      <div className={`p-2 ${shouldModalDisplayRows ? '' : 'x11'}`}>
        {isLoading && (
          <CircularProgress color="primary" />
        )}
        {items.map(item => (
          <div
            className="mb-1 mr-1"
            key={item.id}
            onClick={() => onValidate(item.id)}
          >
            {renderItem(item, item)}
          </div>
        ))}
      </div>
    </Dialog>
  )
}

export default JsonCreator
