import JsonAdministrator from '../../components/JsonAdministrator'
import BattleMapPreview from '../../components/BattleMapPreview'

import { battleMapCreatorLocalStorageKey } from '../../configuration'

function BattleMaps() {
  return (
    <JsonAdministrator
      title="Maps"
      itemName="Map"
      collectionName="battleMaps"
      routeName="maps"
      localStorageKey={battleMapCreatorLocalStorageKey}
      nItemsPerRow={3}
      renderItem={battleMap => (
        <BattleMapPreview
          battleMap={battleMap}
          width={320}
        />
      )}
    />
  )
}

export default BattleMaps
