import { useCallback, useEffect, useMemo, useState } from 'react'
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore'

import { MenuItem, Select } from '@mui/material'

import { db } from '../firebase'
import mobImages from '../data/mob-images.json'

function FamiliesAdministrator({ onChange }) {
  const [families, setFamilies] = useState([])

  const fetchFamilies = useCallback(async () => {
    const fetchedItems = []
    const querySnapshot = await getDocs(collection(db, 'families'))

    querySnapshot.forEach(doc => {
      fetchedItems.push(doc.data())
    })
    setFamilies(fetchedItems)
  }, [])

  useEffect(fetchFamilies, [fetchFamilies])

  const mobNameToFamilies = useMemo(() => {
    const mobNameToFamilies = {}

    families.forEach(family => {
      (family.prefabNames || []).forEach(mobName => {
        if (!mobNameToFamilies[mobName]) mobNameToFamilies[mobName] = []

        mobNameToFamilies[mobName].push(family.id)
      })
    })

    return mobNameToFamilies
  }, [families])

  function handleMobFamilyChange(mobName) {
    return async familyIds => {
      const mobFamilies = mobNameToFamilies[mobName] || []
      const removedFamilies = mobFamilies.filter(familyId => !familyIds.includes(familyId))
      const addedFamilies = familyIds.filter(familyId => !mobFamilies.includes(familyId))

      await Promise.all([
        ...removedFamilies.map(familyId => {
          const family = families.find(family => family.id === familyId)

          if (!family) return

          return updateDoc(doc(db, 'families', family.id), { prefabNames: (family.prefabNames || []).filter(prefabName => prefabName !== mobName) })
        }),
        ...addedFamilies.map(familyId => {
          const family = families.find(family => family.id === familyId)

          if (!family) return

          return updateDoc(doc(db, 'families', family.id), { prefabNames: [...(family.prefabNames || []), mobName] })
        }),
      ])

      await fetchFamilies()

      onChange()
    }
  }

  return (
    <div className="x11">
      {mobImages.map(x => x.split('.png')[0]).map(mobName => (
        <MobFamilySelector
          key={mobName}
          mobName={mobName}
          mobFamilies={mobNameToFamilies[mobName] || []}
          families={families || []}
          onChange={handleMobFamilyChange(mobName)}
        />
      ))}
    </div>
  )
}

function MobFamilySelector({ mobName, mobFamilies, families, onChange }) {
  return (
    <div className="y8">
      <img
        src={`/images/mobs/${mobName}.png`}
        width={128}
      />
      <Select
        multiple
        value={mobFamilies}
        onChange={event => onChange(event.target.value)}
      >
        {families.map(family => (
          <MenuItem
            key={family.name}
            value={family.id}
          >
            {family.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

export default FamiliesAdministrator
