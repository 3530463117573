import { Link as RouterLink } from 'react-router-dom'
import { AppBar, Container, Link, Toolbar, Typography } from '@mui/material'

import { battleMapCreatorLocalStorageKey, spellCreatorLocalStorageKey } from '../configuration'

function ApplicationLayout({ children }) {

  function handleClearLocalStorage() {
    localStorage.clear(battleMapCreatorLocalStorageKey)
    localStorage.clear(spellCreatorLocalStorageKey)

    window.alert('Local Storage cleared!')
  }

  return (
    <>
      <AppBar position="relative">
        <Toolbar>
          <Link
            color="inherit"
            component={RouterLink}
            to="/"
            underline="none"
          >
            <Typography variant="h6">
              Phenomenom
            </Typography>
          </Link>
          <div className="x4 ml-4 flex-grow">
            <Link
              color="inherit"
              component={RouterLink}
              to="/spells"
              underline="none"
            >
              <Typography>
                Spells
              </Typography>
            </Link>
            <Link
              color="inherit"
              component={RouterLink}
              to="/decks"
              underline="none"
              className="ml-2"
            >
              <Typography>
                Decks
              </Typography>
            </Link>
            <Link
              color="inherit"
              component={RouterLink}
              to="/families"
              underline="none"
              className="ml-2"
            >
              <Typography>
                Families
              </Typography>
            </Link>
            <Link
              color="inherit"
              component={RouterLink}
              to="/mobs"
              underline="none"
              className="ml-2"
            >
              <Typography>
                Mobs
              </Typography>
            </Link>
            <Link
              color="inherit"
              component={RouterLink}
              to="/maps"
              underline="none"
              className="ml-2"
            >
              <Typography>
                Maps
              </Typography>
            </Link>
            <Link
              color="inherit"
              component={RouterLink}
              to="/battles"
              underline="none"
              className="ml-2"
            >
              <Typography>
                Battles
              </Typography>
            </Link>
            <div className="flex-grow" />
            <Link
              color="inherit"
              onClick={handleClearLocalStorage}
              underline="none"
              className="ml-2"
            >
              <Typography>
                Clear local storage
              </Typography>
            </Link>
          </div>
        </Toolbar>
      </AppBar>
      <Container
        maxWidth="lg"
        className="pt-2 pb-12"
      >
        {children}
      </Container>
    </>
  )
}

export default ApplicationLayout
