import { Typography } from '@mui/material'

import Fetchable from './Fetchable'
import SpellPreview from './SpellPreview'

function DeckPreview({ deck, small, lineup = 8 }) {
  return (
    <div
      className="w100"
      style={small ? ({ height: `calc(${Math.ceil((deck.composition || []).length / lineup) * 91 + 32} * ${0.485}px)`, overflow: 'hidden' }) : null}
    >
      <div
        style={{
          transform: small ? 'scale(0.485)' : null,
          transformOrigin: 'top left',
          width: small ? `${100 / 0.485}%` : '100%',
        }}
        className="y8"
      >
        <Typography
          variant="h6"
          align="center"
          noWrap
        >
          {deck.name}
        </Typography>
        <div className="x11 no-select">
          {(deck.composition || []).map(({ spellId, quantity }) => (
            <Fetchable
              key={spellId}
              id={spellId}
              collectionName="spells"
              render={spell => (
                <div
                  key={spellId}
                  className="x4 position-relative"
                >
                  <SpellPreview
                    small
                    spell={spell}
                  />
                  <Typography
                    className="x5 position-absolute top-0 bottom-0 left-0 right-0 color-white font-weight-600 text-outline pt-2"
                    variant="h4"
                  >
                    {quantity}
                  </Typography>
                </div>
              )}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default DeckPreview
