import { CircularProgress } from '@mui/material'
import { doc, getDoc } from 'firebase/firestore'
import { useCallback, useEffect, useState } from 'react'

import { db } from '../firebase'

function Fetchable({
  item,
  collectionName,
  id,
  render,
}) {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const fetchData = useCallback(async () => {
    setIsLoading(true)

    const docRef = doc(db, collectionName, id)
    const docSnapshot = await getDoc(docRef)

    if (docSnapshot.exists()) {
      setData(docSnapshot.data())
    }

    setIsLoading(false)
  }, [collectionName, id])

  useEffect(fetchData, [fetchData])

  if (item) return render(item)

  if (isLoading) {
    return (
      <CircularProgress color="primary" />
    )
  }

  if (!data) {
    return 'Error'
  }

  return render(data)
}

export default Fetchable
