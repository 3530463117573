import { useState } from 'react'

import JsonAdministrator from '../../components/JsonAdministrator'
import FamilyPreview from '../../components/FamilyPreview'
import FamiliesAdministrator from '../../components/FamiliesAdministrator'

import { familyCreatorLocalStorageKey } from '../../configuration'

const retNull = () => null

function Families() {
  const [refetch, setRefetch] = useState(() => retNull)

  console.log('refetch', refetch)

  return (
    <>
      <JsonAdministrator
        title="Families"
        itemName="Family"
        collectionName="families"
        routeName="families"
        localStorageKey={familyCreatorLocalStorageKey}
        nItemsPerRow={1}
        renderItem={family => (
          <FamilyPreview
            family={family}
          />
        )}
        setRefetch={setRefetch}
      />
      <FamiliesAdministrator onChange={refetch} />
    </>
  )
}

export default Families
