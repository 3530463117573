import { Typography } from '@mui/material'

function SpellPreview({ spell, small }) {
  if (small) {
    return (
      <div
        style={{ maxWidth: 64 }}
        className="y8"
      >
        <Typography
          noWrap
          align="center"
          variant="body2"
          style={{ maxWidth: 64 }}
        >
          {spell.name || '(No name)'}
        </Typography>
        <img
          width={64}
          src={`/images/spells/${spell.imageName}.png`}
          alt={spell.name}
        />
      </div>
    )
  }

  return (
    <div className="y8">
      <Typography
        noWrap
        align="center"
        variant="h6"
      >
        {spell.name || '(No name)'}
      </Typography>
      <img
        width={128}
        src={`/images/spells/${spell.imageName}.png`}
        alt={spell.name}
      />
    </div>
  )
}

export default SpellPreview
