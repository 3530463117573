import elements from '../data/elements.json'
import spellImages from '../data/spell-images.json'

const elementsDotDescription = {
  Fire: 'At the begining of the turn',
  Plant: 'At the end of the turn',
  Water: 'When melee attacked',
  Earth: 'When MP are used',
  Air: 'When distance attacked',
  Ether: 'When AP are used',
}

const schema = {
  id: {
    type: 'id',
    description: 'The unique id of the spell.',
  },
  createdAt: {
    type: 'now',
    description: 'The date the spell was created.',
  },
  imageName: {
    type: 'image',
    description: 'The image for the spell.',
    names: spellImages,
    location: 'images/spells',
  },
  name: {
    type: 'text',
    description: 'The English name of the spell.',
  },
  description: {
    type: 'textarea',
    description: 'The description of the spell.',
  },
  // types: {
  //   type: 'multiselect',
  //   description: 'The types of the spell.',
  //   values: ['offence', 'defence', 'buff', 'movement', 'invocation'],
  // },
  action: {
    type: 'integer',
    description: 'The number of AP of the spell.',
  },
  minRange: {
    type: 'integer',
    description: 'The min range of the spell. 0 means on character.',
  },
  maxRange: {
    type: 'integer',
    description: 'The max range of the spell. 0 means on character.',
  },
  isLinesOfSightIndependent: {
    type: 'boolean',
    description: 'Weither the spell ignores lines of sight',
  },
  rangeModifier: {
    type: 'text',
    description: 'Can be: line, or anything else',
  },
  ...elements.map(element => ({ [`${element}Damage`]: { type: 'minmaxcritical', description: `The ${element.toUpperCase()} damages the spell inflicts`, mechanic: 'Offense' } })).reduce((acc, obj) => ({ ...acc, ...obj }), {}),
  ...elements.map(element => ({ [`${element.toLowerCase()}DamageOverTime`]: { type: 'integer', description: `The ${element.toUpperCase()} damages over time the spell inflicts. ${elementsDotDescription[element]}.`, mechanic: 'Offense' } })).reduce((acc, obj) => ({ ...acc, ...obj }), {}),
  damageOverTimeDuration: {
    type: 'integer',
    description: 'The number of turn the DOT last',
  },
  isDamageOverTimeCure: {
    type: 'boolean',
    description: 'Weither the spell can cure DOT.',
    mechanic: 'Healing',
  },
  fixedHealth: {
    type: 'integer',
    description: 'The amount of health the spell gives.',
    mechanic: 'Healing',
  },
  maxHealthRatio: {
    type: 'float',
    description: 'The amount of health the spell gives, as ratio of the character\'s maximum health. For 100% input 1.',
    mechanic: 'Healing',
  },
  fixedShield: {
    type: 'integer',
    description: 'The amount of shield the spell gives.',
    mechanic: 'Defense',
  },
  healthShieldRatio: {
    type: 'float',
    description: 'The amount of shield the spell gives, as a ratio of the character\'s current health. For 100% input 1.',
    mechanic: 'Defense',
  },
  maxHealthShieldRatio: {
    type: 'float',
    description: 'The amount of shield the spell gives, as a ratio of the character\'s maximum health. For 100% input 1.',
    mechanic: 'Defense',
  },
  actionCaster: {
    type: 'integer',
    description: 'The amount of AP the spell gives to the caster.',
    mechanic: 'Buff',
  },
  actionTarget: {
    type: 'integer',
    description: 'The amount of AP the spell gives to the target.',
    mechanic: 'Buff',
  },
  actionDuration: {
    type: 'integer',
    description: 'The number of turns the AP modification lasts.',
  },
  movementCaster: {
    type: 'integer',
    description: 'The amount of MP the spell gives to the caster.',
    mechanic: 'Buff',
  },
  movementTarget: {
    type: 'integer',
    description: 'The amount of MP the spell gives to the target.',
    mechanic: 'Buff',
  },
  movementDuration: {
    type: 'integer',
    description: 'The number of turns the MP modification lasts.',
    mechanic: 'Buff',
  },
  initiativeCaster: {
    type: 'integer',
    description: 'The amount of initiative the spell gives to the caster.',
    mechanic: 'Buff',
  },
  initiativeTarget: {
    type: 'integer',
    description: 'The amount of initiative the spell gives to the target.',
    mechanic: 'Buff',
  },
  initiativeDuration: {
    type: 'integer',
    description: 'he number of turns the initiative lasts.',
  },
  tackleCaster: {
    type: 'integer',
    description: 'The amount of tackle the spell gives to the caster.',
    mechanic: 'Buff',
  },
  tackleTarget: {
    type: 'integer',
    description: 'The amount of tackle the spell gives to the target.',
    mechanic: 'Buff',
  },
  tackleDuration: {
    type: 'integer',
    description: 'The number of turns the tackle lasts.',
  },
  escapeCaster: {
    type: 'integer',
    description: 'The amount of escape the spell gives to the caster.',
    mechanic: 'Buff',
  },
  escapeTarget: {
    type: 'integer',
    description: 'The amount of escape the spell gives to the target.',
    mechanic: 'Buff',
  },
  escapeDuration: {
    type: 'integer',
    description: 'The number of turns the escape lasts.',
  },
  meleeResistance: {
    type: 'float',
    description: 'The melee resistance given. For 100% input 1.',
    mechanic: 'Defense',
  },
  distanceResistance: {
    type: 'float',
    description: 'The distance resistance given. For 100% input 1.',
    mechanic: 'Defense',
  },
  ...elements.map(element => ({ [`${element.toLowerCase()}Resistance`]: { type: 'float', description: `The ${element.toUpperCase()} resistance given. For 100% input 1.`, mechanic: 'Defense' } })).reduce((acc, obj) => ({ ...acc, ...obj }), {}),
  resistanceDuration: {
    type: 'integer',
    description: 'The number of turns the resistance lasts.',
  },
  isPierceArmor: {
    type: 'boolean',
    description: 'Weither the spell can ignore the opponent\' shield or not',
  },
  isTurnPasser: {
    type: 'boolean',
    description: 'Weither the spell can make the opponent pass one turn',
    mechanic: 'Buff',
  },
  invulnerabilityTurns: {
    type: 'integer',
    description: 'The number of turn the character becomes invulnerable.',
    mechanic: 'Defense',
  },
  meleeInvulnerabilityTurns: {
    type: 'integer',
    description: 'The number of turn the character becomes melee invulnerable.',
    mechanic: 'Defense',
  },
  distanceInvulnerabilityTurns: {
    type: 'integer',
    description: 'The number of turn the character becomes distance invulnerable.',
    mechanic: 'Defense',
  },
  ...elements.map(element => ({ [`${element.toLowerCase()}InvulnerabilityTurns`]: { type: 'integer', description: `The number of turn the character becomes ${element.toUpperCase()} invulnerable.`, mechanic: 'Defense' } })).reduce((acc, obj) => ({ ...acc, ...obj }), {}),
  isVolatile: {
    type: 'boolean',
    description: 'Weither the card goes to the dump after it is played',
  },
  cardsDrawn: {
    type: 'integer',
    description: 'The number of cards the spell drawns.',
    mechanic: 'Card',
  },
  attraction: {
    type: 'integer',
    description: 'The number of cells the spell attracts.',
    mechanic: 'Movement',
  },
  repulsion: {
    type: 'integer',
    description: 'The number of cells the spell pushes.',
    mechanic: 'Movement',
  },
  isPositionSwitchWithAlly: {
    type: 'boolean',
    description: 'Weither the spell switches position with an ally.',
    mechanic: 'Movement',
  },
  zone: {
    type: 'zone',
    description: 'The spell zone.',
  },
}

export default schema
