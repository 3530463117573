import { Typography } from '@mui/material'

import BattleMapPreview from './BattleMapPreview'
import Fetchable from './Fetchable'
import MobPreview from './MobPreview'

function BattlePreview({ battle }) {
  return (
    <div>
      <Typography
        noWrap
        variant="h5"
        fontWeight={500}
      >
        {battle.name}
      </Typography>
      <div className="x1">
        {!!battle.battleMapId && (
          <Fetchable
            id={battle.battleMapId}
            collectionName="battleMaps"
            render={battleMap => (
              <BattleMapPreview
                battleMap={battleMap}
                width={256}
              />
            )}
          />
        )}
        <div className="x11 ml-2">
          {(battle.mobIds || []).map(mobId => (
            <Fetchable
              key={mobId}
              id={mobId}
              collectionName="mobs"
              render={mob => (
                <MobPreview
                  small
                  mob={mob}
                />
              )}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default BattlePreview
