import JsonAdministrator from '../../components/JsonAdministrator'
import SpellPreview from '../../components/SpellPreview'

import { spellCreatorLocalStorageKey } from '../../configuration'

function Spells() {
  return (
    <JsonAdministrator
      title="Spells"
      itemName="Spell"
      collectionName="spells"
      routeName="spells"
      localStorageKey={spellCreatorLocalStorageKey}
      nItemsPerRow={6}
      renderItem={spell => (
        <SpellPreview
          spell={spell}
        />
      )}
    />
  )
}

export default Spells
