import { useTheme } from '@mui/material'

function Radar({ data, size, ...props }) {
  const theme = useTheme()

  const textOffset = size / 10

  const keys = Object.keys(data)
  const n = keys.length
  const angle = 2 * Math.PI / n

  let max = 0
  const nodes = []

  keys.forEach(key => {
    max = Math.max(max, data[key].value)
  })

  keys.forEach((key, i) => {
    nodes.push(
      <line
        key={i}
        x1={0}
        y1={0}
        x2={size / 2 * Math.cos(i * angle - Math.PI / 2)}
        y2={size / 2 * Math.sin(i * angle - Math.PI / 2)}
        stroke={theme.palette.divider}
      />,
      <line
        key={`${i}side`}
        x1={size / 2 * Math.cos(i * angle - Math.PI / 2)}
        y1={size / 2 * Math.sin(i * angle - Math.PI / 2)}
        x2={size / 2 * Math.cos((i + 1) * angle - Math.PI / 2)}
        y2={size / 2 * Math.sin((i + 1) * angle - Math.PI / 2)}
        stroke={theme.palette.divider}
      />,
    )
  })

  keys.forEach((key, i) => {
    const nextKey = i === keys.length - 1 ? keys[0] : keys[i + 1]

    nodes.push(
      <line
        key={`${i}value`}
        x1={data[key].value / max * size / 2 * Math.cos(i * angle - Math.PI / 2)}
        y1={data[key].value / max * size / 2 * Math.sin(i * angle - Math.PI / 2)}
        x2={data[nextKey].value / max * size / 2 * Math.cos((i + 1) * angle - Math.PI / 2)}
        y2={data[nextKey].value / max * size / 2 * Math.sin((i + 1) * angle - Math.PI / 2)}
        stroke={theme.palette.primary.main}
        strokeWidth={size / 80}
      />,
      <circle
        cx={data[key].value / max * size / 2 * Math.cos(i * angle - Math.PI / 2)}
        cy={data[key].value / max * size / 2 * Math.sin(i * angle - Math.PI / 2)}
        r={size / 64}
        key={`${i}circle`}
        fill={theme.palette.primary.main}
      />,
    )
  })

  keys.forEach((key, i) => {
    nodes.push(
      <text
        key={`${i}text`}
        x={(size / 2 + textOffset) * Math.cos(i * angle - Math.PI / 2)}
        y={(size / 2 + textOffset) * Math.sin(i * angle - Math.PI / 2)}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          userSelect: 'none',
          fontSize: size / 7,
        }}
      >
        {data[key].symbol}
      </text>
    )
  })

  return (
    <svg
      viewBox={`0 0 ${size} ${size}`}
      width={size}
      {...props}
    >
      <g transform={`translate(${size / 2}, ${size / 2}), scale(0.72)`}>
        {nodes}
      </g>
    </svg>
  )
}

export default Radar
