import { Typography } from '@mui/material'

import { deckCreatorLocalStorageKey } from '../../configuration'

import JsonCreator from '../../components/JsonCreator'
import SpellPreview from '../../components/SpellPreview'
import Fetchable from '../../components/Fetchable'

const schema = {
  id: {
    type: 'id',
    description: 'The unique id of the deck.',
  },
  createdAt: {
    type: 'now',
    description: 'The date the deck was created.',
  },
  name: {
    type: 'text',
    description: 'The English name of the deck.',
  },
  description: {
    type: 'textarea',
    description: 'The description of the deck.',
  },
  composition: {
    type: 'list',
    description: 'The spell composition of the deck.',
    schema: {
      type: 'object',
      schema: {
        spellId: {
          type: 'spell',
          description: 'The spell of the deck.',
          collectionName: 'spells',
          renderItem: (id, item) => (
            <Fetchable
              item={item}
              collectionName="spells"
              id={id}
              render={spell => (
                <SpellPreview
                  spell={spell}
                />
              )}
            />
          ),
        },
        quantity: {
          type: 'integer',
          description: 'The quantity of cards of the spell in the deck.',
        },
      },
    },
  },
}

function DeckCreatorInformations({ data }) {
  return (
    <Typography>
      This deck has {(data.composition || []).map(x => x.quantity).reduce((sum, x) => sum + x, 0)} cards.
    </Typography>
  )
}

function DeckCreator() {
  return (
    <JsonCreator
      title="Deck Creator"
      schema={schema}
      collectionName="decks"
      localStorageKey={deckCreatorLocalStorageKey}
      Informations={DeckCreatorInformations}
    />
  )
}

export default DeckCreator
