import { Typography } from '@mui/material'

import schema from '../schemas/mob'

import DeckPreview from './DeckPreview'
import Fetchable from './Fetchable'

const workSchema = { ...schema }

delete workSchema.id
delete workSchema.deckId
delete workSchema.createdAt
delete workSchema.name
delete workSchema.prefabName
delete workSchema.cellSize

function MobPreview({ mob, small }) {
  const size = small ? 128 + 64 : 128 + 64 + 32 + 16 + 8 + 4 + 2 + 1

  function renderStats() {
    const nodes = []

    Object.keys(workSchema).forEach(key => {
      if (!mob[key]) return

      nodes.push(
        <Typography
          key={key}
          variant="body2"
          className="x2 w100"
        >
          {key}:<span className="flex-grow" />{mob[key]}
        </Typography>
      )
    })

    return nodes
  }

  return (
    <div style={{ maxWidth: size }}>
      <Typography
        noWrap
        variant="h6"
        align="center"
      >
        {mob.name}
      </Typography>
      <div className="position-relative no-select">
        <img
          src={`/images/mobs/${mob.prefabName}.png`}
          alt={mob.prefabName}
          width={size}
        />
        <div className="position-absolute top-0 left-0 right-0 p-1">
          {renderStats()}
        </div>
      </div>
      {!!mob.deckId && (
        <Fetchable
          id={mob.deckId}
          collectionName="decks"
          render={deck => (
            <DeckPreview
              small
              lineup={small ? 6 : 8}
              deck={deck}
            />
          )}
        />
      )}
    </div>
  )
}

export default MobPreview
