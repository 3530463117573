// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { CACHE_SIZE_UNLIMITED, enableIndexedDbPersistence, initializeFirestore } from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB8NhZF1bdZS1kZywE5la-iZPKIxWni2Qw',
  authDomain: 'phenomenom.firebaseapp.com',
  projectId: 'phenomenom',
  storageBucket: 'phenomenom.appspot.com',
  messagingSenderId: '691937340519',
  appId: '1:691937340519:web:115d855eeb884b17b678c2',
  measurementId: 'G-L1044W8KZP',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const db = initializeFirestore(app, { cacheSizeBytes: CACHE_SIZE_UNLIMITED })

enableIndexedDbPersistence(db)
.catch(err => {
  if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
  }
  else if (err.code === 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
  }
})
