import { Typography } from '@mui/material'
import './BattleMapPreview.css'

const cellSize = 36
const cellCostToColor = {
  1: 'LimeGreen',
  2: 'antiquewhite',
  3: 'LightSlateGrey',
}

function BattleMapPreview({ battleMap, onClick = () => null, width = 512 + 256 + 128 }) {
  const scale = Math.min(1, width / ((Math.max(battleMap.rows, battleMap.columns) || 1) * cellSize))

  const nodes = []

  for (let i = 0; i < battleMap.rows; i++) {
    const row = []

    for (let j = 0; j < battleMap.columns; j++) {
      const [cost, elevation, startingPosition] = battleMap.cells.slice(3 * (i * battleMap.columns + j), 3 * (i * battleMap.columns + j) + 3)

      row.push(
        <div
          key={`${i}_${j}`}
          onClick={() => onClick({ row: i, column: j })}
          className="x5 no-select cursor-pointer BattleMapPreview-cell"
          style={{
            minWidth: cellSize,
            minHeight: cellSize,
            width: cellSize,
            height: cellSize,
            color: startingPosition === 'h' ? 'blue' : startingPosition === 'm' ? 'red' : 'unset',
            backgroundColor: ['h', 'l', 'x'].includes(elevation) ? 'white' : cellCostToColor[cost],
          }}
        >
          {elevation}
        </div>
      )
    }

    nodes.push(
      <div
        key={i}
        className="x40"
      >
        {row}
      </div>
    )
  }

  return (
    <div style={{ width }}>
      {!!battleMap.name && (
        <Typography
          noWrap
          variant="h6"
          align="center"
        >
          {battleMap.name}
        </Typography>
      )}
      <div
        style={{ height: battleMap.rows * cellSize * scale, overflow: 'hidden' }}
      >
        <div
          className="BattleMapPreview"
          style={{ transform: `scale(${scale})` }}
        >
          {nodes}
        </div>
      </div>
    </div>
  )
}

export default BattleMapPreview
