import BattlePreview from '../../components/BattlePreview'
import JsonAdministrator from '../../components/JsonAdministrator'

import { battleCreatorLocalStorageKey } from '../../configuration'

function Battles() {
  return (
    <JsonAdministrator
      title="Battles"
      itemName="Battle"
      collectionName="battles"
      routeName="battles"
      localStorageKey={battleCreatorLocalStorageKey}
      nItemsPerRow={1}
      renderItem={battle => (
        <BattlePreview
          battle={battle}
        />
      )}
      itemClassname="y1"
    />
  )
}

export default Battles
