import JsonCreator from '../../components/JsonCreator'

import { battleMapCreatorLocalStorageKey } from '../../configuration'

const schema = {
  id: {
    type: 'id',
    description: 'The unique id of the map.',
  },
  createdAt: {
    type: 'now',
    description: 'The date the map was created.',
  },
  name: {
    type: 'text',
    description: 'The English name of the map.',
  },
  cells: {
    type: 'cells',
    description: 'The cells of the map.',
  },
}

function BattleMapCreator() {
  return (
    <JsonCreator
      title="Map Creator"
      schema={schema}
      collectionName="battleMaps"
      localStorageKey={battleMapCreatorLocalStorageKey}
    />
  )
}

export default BattleMapCreator
