import JsonAdministrator from '../../components/JsonAdministrator'
import MobPreview from '../../components/MobPreview'

import { mobCreatorLocalStorageKey } from '../../configuration'

function Mobs() {
  return (
    <JsonAdministrator
      title="Mobs"
      itemName="Mob"
      collectionName="mobs"
      routeName="mobs"
      localStorageKey={mobCreatorLocalStorageKey}
      nItemsPerRow={4}
      renderItem={mob => (
        <MobPreview
          mob={mob}
        />
      )}
    />
  )
}

export default Mobs
