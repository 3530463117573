import mobImages from '../data/mob-images.json'

const schema = {
  id: {
    type: 'id',
    description: 'The unique id of the mob.',
  },
  createdAt: {
    type: 'now',
    description: 'The date the mob was created.',
  },
  name: {
    type: 'text',
    description: 'The name of mob.',
  },
  prefabNames: {
    type: 'list',
    description: 'The prefab names of the mob.',
    schema: {
      type: 'prefab',
      description: 'The prefab of the mob.',
      names: mobImages,
      location: 'images/mobs',
      shouldDisplayName: true,
    },
  },
}

export default schema
