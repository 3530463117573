import DeckPreview from '../components/DeckPreview'
import Fetchable from '../components/Fetchable'
import elements from '../data/elements.json'
import mobImages from '../data/mob-images.json'

const schema = {
  id: {
    type: 'id',
    description: 'The unique id of the mob.',
  },
  createdAt: {
    type: 'now',
    description: 'The date the mob was created.',
  },
  name: {
    type: 'text',
    description: 'The name of mob.',
  },
  prefabName: {
    type: 'prefab',
    description: 'The prefab of the mob.',
    names: mobImages,
    location: 'images/mobs',
    shouldDisplayName: true,
  },
  deckId: {
    type: 'deck',
    description: 'The deck of the mob.',
    collectionName: 'decks',
    shouldModalDisplayRows: true,
    renderItem: (id, item) => (
      <Fetchable
        item={item}
        collectionName="decks"
        id={id}
        render={deck => (
          <DeckPreview
            deck={deck}
          />
        )}
      />
    ),
  },
  cellSize: {
    type: 'integer',
    default: 1,
    description: 'The cell size of mob.',
  },
  health: {
    type: 'integer',
    description: 'The health of the mob.',
  },
  action: {
    type: 'integer',
    description: 'The AP of the mob.',
  },
  movement: {
    type: 'integer',
    description: 'The MP of the mob.',
  },
  ...elements.map(element => ({ [element.toLowerCase()]: { type: 'integer', description: `The ${element} stat of the mob.` } })).reduce((acc, obj) => ({ ...acc, ...obj }), {}),
}

export default schema
