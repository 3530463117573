import { Typography } from '@mui/material'

function FamilyPreview({ family }) {
  return (
    <div
      className="w100"
    >
      <Typography
        variant="h6"
        align="center"
        noWrap
      >
        {family.name}
      </Typography>
      <div className="x21">
        {(family.prefabNames || []).map(prefabName => (
          <img
            key={prefabName}
            src={`/images/mobs/${prefabName}.png`}
            width={128}
          />
        ))}
      </div>
    </div>
  )
}

export default FamilyPreview
