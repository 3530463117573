import { spellCreatorLocalStorageKey } from '../../configuration'

import JsonCreator from '../../components/JsonCreator'

import schema from '../../schemas/spell'

function SpellCreator() {
  return (
    <JsonCreator
      title="Spell Creator"
      schema={schema}
      collectionName="spells"
      localStorageKey={spellCreatorLocalStorageKey}
    />
  )
}

export default SpellCreator
