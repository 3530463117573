import { battleCreatorLocalStorageKey } from '../../configuration'

import JsonCreator from '../../components/JsonCreator'
import BattleMapPreview from '../../components/BattleMapPreview'
import Fetchable from '../../components/Fetchable'
import MobPreview from '../../components/MobPreview'

const schema = {
  id: {
    type: 'id',
    description: 'The unique id of the battle.',
  },
  createdAt: {
    type: 'now',
    description: 'The date the battle was created.',
  },
  name: {
    type: 'text',
    description: 'The English name of the battle.',
  },
  description: {
    type: 'textarea',
    description: 'The description of the battle.',
  },
  battleMapId: {
    type: 'battleMap',
    description: 'The BattleMap of the battle.',
    collectionName: 'battleMaps',
    renderItem: (id, item) => (
      <Fetchable
        item={item}
        collectionName="battleMaps"
        id={id}
        render={battleMap => (
          <BattleMapPreview
            battleMap={battleMap}
            width={256}
          />
        )}
      />
    ),
  },
  mobIds: {
    type: 'list',
    description: 'The mobs of the battle.',
    schema: {
      type: 'mob',
      description: 'The Mobs of the battle.',
      collectionName: 'mobs',
      renderItem: (id, item) => (
        <Fetchable
          item={item}
          collectionName="mobs"
          id={id}
          render={mob => (
            <MobPreview
              mob={mob}
            />
          )}
        />
      ),
    },
  },
}

function BattleCreator() {
  return (
    <JsonCreator
      title="Battle Creator"
      schema={schema}
      collectionName="battles"
      localStorageKey={battleCreatorLocalStorageKey}
    />
  )
}

export default BattleCreator
