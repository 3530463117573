import ElementsRadar from '../../components/ElementsRadar'
import MechanicsRadar from '../../components/MechanicsRadar'

function Home() {

  return (
    <div className="x1">
      <pre className="p-2">
        <code>
          {`const elementToSymbol = {
  Fire: '🔥',
  Ether: '🧠',
  Plant: '🌱',
  Air: '🌀',
  Water: '💧',
  Earth: '🗿',
}
const mechanicToSymbol = {
  Melee: '⚔️',
  Distance: '🏹',
  Movement: '➡️',
  Invocation: '🐈',
  Card: '🃏',
  Buff: '💡',
  Healing: '🧪',
  Defense: '🛡',
}`}
        </code>
      </pre>
      <ElementsRadar
        data={{ Fire: 5, Plant: 2, Water: 3, Earth: 4, Air: 1, Ether: 2 }}
        size={200}
        className="ml-2"
      />
      <MechanicsRadar
        data={{ Melee: 4, Distance: 3, Defense: 3, Healing: 1, Buff: 4, Movement: 2, Invocation: 3, Card: 1 }}
        size={200}
        className="ml-2"
      />
      <ElementsRadar
        data={{ Fire: 5, Plant: 2, Water: 3, Earth: 4, Air: 1, Ether: 2 }}
        size={50}
        className="ml-2 mt-4"
      />
      <MechanicsRadar
        data={{ Melee: 4, Distance: 3, Defense: 3, Healing: 1, Buff: 4, Movement: 2, Invocation: 3, Card: 1 }}
        size={50}
        className="ml-2 mt-4"
      />
    </div>
  )
}

export default Home
